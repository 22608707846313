import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { HTMLAttributes } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { MOMENT_TIME_FORMAT } from '../../constants'
import moment from '../../lib/moment-fi'
import { formatDuration } from '../../lib/time'
import { isPassed, nowSelector } from '../../Selectors'
import { color, getColor, PaddingSize, theme } from '../../Theme'
import { AppState, Color, Timestamp } from '../../types'
import { ColorProps, PastProps } from '../../types/App'
import { EmphasisNormal } from '../Emphasis'
import Text from '../Text'
import DurationIcon from './DurationIcon'

interface RightPaddingProps extends HTMLAttributes<HTMLDivElement> {
  size?: PaddingSize
}

const Row = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  display: flex;
  margin-top: ${theme.spacing.sizes.small};
  min-width: ${theme.maxWidths.column};
`

const Container = styled.div<PastProps>`
  ${theme.spacing.all('small')};
  ${theme.spacing.right('biggest')};
  background-color: ${(p) =>
    getColor(p.theme, [p.past ? 'grayBlue' : 'shallowBlue'], ['nightGray'])};
  border-radius: ${theme.borderRadius.button};
  flex: 1;
  margin-left: 46px;
  margin-right: ${theme.spacing.sizes.small};
  position: relative;
`

const FlexRow = styled.div`
  ${theme.layout.flexRow};
  flex: 1;
  justify-content: flex-start;
  position: relative;
`

const Title = styled.div<ColorProps>`
  ${theme.text('normal', 'decorative', 'bold')};
  color: ${(p) => color(p.color, 'white')};
`

const RightPadding = styled.div<RightPaddingProps>`
  ${(p) => theme.spacing.right(p.size || 'small')};
`

const IconPadding = styled(RightPadding)<ColorProps>`
  ${(p) => theme.spacing.right(p.size || 'small')};
  color: ${(p) => color(p.color)};
  min-width: ${theme.spacing.sizes.large};
  padding-top: 2px;
`

type Props = {
  t: TFunction
  past?: boolean
  textColor: Color
  startTime?: string
  endTime?: string
  duration?: string
}

const CalendarRest = ({ t, past, textColor, startTime, endTime, duration }: Props) => (
  <Row>
    <Container past={past}>
      <Title color={textColor}>{t('scheduleRest')}</Title>
      <FlexRow>
        <RightPadding>
          <Text color={textColor}>
            {startTime} - {endTime}{' '}
          </Text>
        </RightPadding>
        <IconPadding color={textColor}>
          <DurationIcon color={textColor} />
        </IconPadding>
        <EmphasisNormal color={textColor}>{duration}</EmphasisNormal>
      </FlexRow>
    </Container>
  </Row>
)

type PropsIn = {
  startDateTime: Timestamp
  endDateTime: Timestamp
  location: string
}

const mapStateToProps = (state: AppState, { startDateTime, endDateTime }: PropsIn) => {
  const now = nowSelector(state)
  const past = isPassed(now, endDateTime)

  const textColor: Color = past ? 'grayDark' : 'primaryBlue'

  const startTime = moment(startDateTime).format(MOMENT_TIME_FORMAT)
  const endTime = moment(endDateTime).format(MOMENT_TIME_FORMAT)

  const rawDuration = moment(endDateTime).diff(moment(startDateTime))
  const duration = formatDuration(rawDuration)

  return {
    past,
    textColor,
    startTime,
    endTime,
    duration,
  }
}

export default withTranslation()(connect(mapStateToProps)(CalendarRest))
