import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import moment from '../../lib/moment-fi'
import { color, getColor, theme } from '../../Theme'
import { CalendarDay } from './CalendarDay'

const Row = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  display: flex;
  margin-top: ${theme.spacing.sizes.small};
  min-width: ${theme.maxWidths.column};
`

const Container = styled.div`
  ${theme.spacing.all('small')};
  ${theme.spacing.right('biggest')};
  background-color: ${(p) => getColor(p.theme, ['grayBlue'], ['nightGray'])};
  border-radius: ${theme.borderRadius.button};
  flex: 1;
  margin-right: ${theme.spacing.sizes.small};
  position: relative;
`

const Title = styled.div`
  ${theme.text('normal', 'decorative', 'bold')};
  color: ${color('grayDark')};
`

type Props = {
  dayType: string
  startDateTime: string
}

const getDayTypeTranslation = (dayType: string, t: TFunction) => {
  switch (dayType) {
    case 'W':
      return t('scheduleWday')
    case 'H':
      return t('scheduleHday')
    default:
      return ''
  }
}

const CalendarRestDay = ({ dayType, startDateTime }: Props) => {
  const { t } = useTranslation()
  return (
    <Row>
      <CalendarDay date={moment(startDateTime)} />
      <Container>
        <Title>{getDayTypeTranslation(dayType, t)}</Title>
      </Container>
    </Row>
  )
}

export default CalendarRestDay
