import {
  CLEAR_FEEDBACKS,
  SET_FEEDBACK_ERROR_MESSAGE,
  SET_FEEDBACK_MESSAGE,
  TOGGLE_FEEDBACK_EMPHASIS,
  TOGGLE_FEEDBACK_ERROR,
  TOGGLE_FEEDBACK_REQUEST_ANSWER,
  UPDATE_FEEDBACK,
  UPDATE_FEEDBACK_STARS,
} from '../actions/feedback'
import { FeedbackAction } from '../actions/feedback'
import { Feedback, FeedbackEmphasis, FeedbackError, FeedbackState } from '../types'
import { replaceWithId } from './Common'

export const initialState: FeedbackState = {
  workspace: {},
  byId: {},
  idsByShiftId: {},
  idsByListId: {},
}

const updateFeedback = (feedback: Feedback, state: FeedbackState) => {
  const id = feedback.type === 'shift' && feedback.shiftId ? feedback.shiftId : feedback.listId

  if (!feedback.id) {
    return {
      ...state,
      workspace: {
        ...state.workspace,
        [`${id}:${feedback.type}`]: feedback,
      },
    }
  }

  const idsByProperty = feedback.type === 'shift' ? 'idsByShiftId' : 'idsByListId'
  return {
    ...state,
    byId: replaceWithId(state.byId, feedback),
    [idsByProperty]: { ...state[idsByProperty], [id]: feedback.id },
  }
}

const feedbacksReducer = (
  state: FeedbackState = initialState,
  action: FeedbackAction
): FeedbackState => {
  let feedback: Feedback,
    newFeedback: Feedback,
    message: string,
    errorMessage: string,
    stars: number,
    error: FeedbackError,
    emphasis: FeedbackEmphasis

  switch (action.type) {
    case TOGGLE_FEEDBACK_ERROR: {
      feedback = action.payload.feedback
      error = action.payload.error

      const errors: Array<FeedbackError> =
        feedback.errors.indexOf(error) === -1
          ? feedback.errors.concat(error)
          : feedback.errors.filter((r) => error !== r)

      newFeedback = { ...feedback, errors }
      return updateFeedback(newFeedback, state)
    }

    case TOGGLE_FEEDBACK_EMPHASIS: {
      feedback = action.payload.feedback
      emphasis = action.payload.emphasis

      const emphasises: Array<FeedbackEmphasis> =
        feedback.emphasis.indexOf(emphasis) === -1
          ? feedback.emphasis.concat(emphasis)
          : feedback.emphasis.filter((r) => emphasis !== r)

      newFeedback = { ...feedback, emphasis: emphasises }
      return updateFeedback(newFeedback, state)
    }

    case SET_FEEDBACK_MESSAGE:
      feedback = action.payload.feedback
      message = action.payload.message
      newFeedback = { ...feedback, message }
      return updateFeedback(newFeedback, state)

    case SET_FEEDBACK_ERROR_MESSAGE:
      feedback = action.payload.feedback
      errorMessage = action.payload.errorMessage
      newFeedback = { ...feedback, errorMessage }
      return updateFeedback(newFeedback, state)

    case TOGGLE_FEEDBACK_REQUEST_ANSWER: {
      feedback = action.payload
      const fieldValue = !feedback.requestAnswer
      newFeedback = { ...feedback, requestAnswer: fieldValue }
      return updateFeedback(newFeedback, state)
    }

    case UPDATE_FEEDBACK_STARS: {
      feedback = action.payload.feedback
      stars = action.payload.stars

      if ((feedback.stars < 3 && stars > 2) || (feedback.stars > 2 && stars < 3)) {
        newFeedback = { ...feedback, errors: [], emphasis: [], stars: feedback.stars }
        return updateFeedback(newFeedback, state)
      }

      newFeedback = { ...feedback, stars: feedback.stars }
      return updateFeedback(newFeedback, state)
    }

    case UPDATE_FEEDBACK:
      return updateFeedback(action.payload, state)

    case CLEAR_FEEDBACKS:
      return initialState

    default:
      return state
  }
}

export default feedbacksReducer
