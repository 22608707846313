import Dexie, { Table } from 'dexie'

import {
  Compositions,
  DBContacts,
  Feedback,
  ObservationMessage,
  Personnel,
  ResponseRead,
  Schedule,
  Session,
  Shift,
  SignInStatus,
  TaskDone,
  TestPushMessage,
  Timestamp,
  TowingAuditMessage,
  TowingFormContent,
  TowingFormState,
  TowingStep,
  Update,
} from '../types'
import { calculatePreviousShiftListStartDay } from './time'

// Types from http://dexie.org/docs/API-Reference
export type Version = {
  stores: (object: object) => Version
  upgrade: (func: (trans: any) => unknown) => Version
}

// Table types have type for saved model and
// union type for indexed fields
export type DexieTables = {
  shifts: Table<Shift, string | number>
  tasksdone: Table<TaskDone, string | number>
  feedbacks: Table<Feedback, string>
  signins: Table<SignInStatus, string>
  reads: Table<ResponseRead, string>
  schedules: Table<Schedule, string | number>
  sessions: Table<Session, string>
  searchedShifts: Table<Shift, [string, Timestamp] | number>
  searchedCompositions: Table<Compositions, [string, string, string] | string>
  updates: Table<Update, Timestamp | 'yes' | 'no'>
  personnel: Table<Personnel, string | Timestamp>
  phoneContacts: Table<DBContacts, string>
  observationMessages: Table<ObservationMessage, [string, Timestamp, Timestamp] | number>
  testPushMessages: Table<TestPushMessage, [string, Timestamp] | number>
  towingForms: Table<TowingFormContent, string | [string, string] | number>
  towingSteps: Table<TowingStep, string | number>
  towingFormStates: Table<TowingFormState, string | Timestamp | [string, string]>
  towingAuditMessages: Table<TowingAuditMessage, string>
}

export type DB<T = DexieTables> = Dexie & T

const db = new Dexie('kentta') as DB

db.version(1).stores({
  shifts: 'id,startsAt',
  schedules: 'userNumber,from,to',
  sessions: 'number,token,exp',
})

// Add feedbacks
db.version(2).stores({
  feedbacks: 'id,shiftId',
})

// Created created_at column & index for schedules
db.version(3)
  .stores({
    schedules: 'userNumber,created_at',
  })
  .upgrade((trans) => {
    trans
      .table('schedules')
      .toCollection()
      .modify((schedule) => {
        schedule.created_at = new Date(schedule.from).valueOf()
      })
  })

// Reformat feedback indexes
db.version(4).stores({
  feedbacks: '[type+shiftId]',
})

// Add shift listStartDates
db.version(5).upgrade((trans) => {
  trans
    .table('shifts')
    .toCollection()
    .modify((shift) => {
      shift.listStartDate = calculatePreviousShiftListStartDay(shift.startsAt)
    })
})

// Feedback format has changed, clear old feedbacks
db.version(6).upgrade((trans) => {
  trans.table('feedbacks').clear()
})

// Add created_at column & index to shifts
db.version(7)
  .stores({
    shifts: 'id,created_at',
  })
  .upgrade((trans) => {
    trans
      .table('shifts')
      .toCollection()
      .modify((shift) => {
        shift.created_at = shift.created_at || 0
      })
  })

// Feedback primary key changes, remove existing feedbacks
db.version(8).stores({
  feedbacks: null,
})
db.version(9).stores({
  feedbacks: 'id',
})

db.version(10).stores({
  signins: 'id',
  reads: 'id',
})

db.version(11).stores({
  donetasks: '[shiftId+index],shiftId',
})

// Backend only returns your feedbacks, so make sure IndexedDB doesn't contain
// previous feedbacks that were for someone else.
db.version(12).upgrade((trans) => {
  trans.table('feedbacks').clear()
})

// IE doesn't support compound indexes, calculate id manually
db.version(13).stores({
  donetasks: null,
})
db.version(14).stores({
  tasksdone: 'id,shiftId',
})
db.version(15).stores({
  tasksdone: 'id,shiftId',
})

db.version(16).stores({
  updates: 'receivedAt,confirmed',
})

db.version(17).stores({
  personnel: 'trainNumber,date',
})

db.version(18).stores({
  personnel: 'trainNumber,deptime',
})

db.version(19).stores({
  personnel: 'trainNumber,depTime',
})

db.version(20).stores({
  personnel: 'trainNumber, [trainNumber+depTime]',
})

db.version(21).stores({
  searchedShifts: '[id+date]',
})

db.version(22).stores({
  searchedCompositions: '[trainNumber+station+date]',
})

db.version(23).stores({
  personnel: '[trainNumber+depTime]',
})

db.version(24)
  .stores({
    personnel: '[trainNumber+depTime], depTime',
  })
  .upgrade((trans) => {
    trans
      .table('personnel')
      .toCollection()
      .modify((personnel) => {
        personnel.depTime = personnel.depTime || 0
      })
  })

db.version(25)
  .stores({
    searchedShifts: '[id+date], created_at',
  })
  .upgrade((trans) => {
    trans
      .table('searchedShifts')
      .toCollection()
      .modify((shift) => {
        shift.created_at = shift.created_at || 0
      })
  })

db.version(26)
  .stores({
    searchedCompositions: '[trainNumber+station+date], timestamp',
  })
  .upgrade((trans) => {
    trans
      .table('searchedCompositions')
      .toCollection()
      .modify((composition) => {
        composition.timestamp = composition.route.listTimestamp || 0
      })
  })

db.version(27)
  .stores({
    shifts: 'id,created_at',
  })
  .upgrade((trans) => {
    trans
      .table('shifts')
      .toCollection()
      .modify((shift) => {
        shift.listStartDate = calculatePreviousShiftListStartDay(shift.startDateTime)
      })
  })

db.version(28).stores({
  phoneContacts: 'id,created_at',
})

db.version(29).stores({
  observationMessages: '[dutyShortName+dutyDate+messageDateTime],created_at',
})

db.version(30).stores({
  testPushMessages: '[messageId+sentAt],created_at',
})

db.version(31).stores({
  towingForms: 'id,equipmentType,received_at',
  towingSteps: 'id,received_at',
  towingFormStates: 'id,lastSaved,[vehicleType+vehicleNumber]',
})

db.version(32).stores({
  towingAuditMessages: 'id',
})

export default db
