import { CLEAR_SHIFTS, RELOAD_SCHEDULE, UPDATE_SCHEDULE } from '../actions/shift'
import { ShiftAction } from '../actions/shift'
import { unixTimestamp } from '../lib/time'
import { Schedule, ShiftState } from '../types'
import { InputShiftHead, Shift } from '../types/Input'
import { replaceManyWithId } from './Common'

export const initialState = {
  loading: false,
  error: '',
  byId: {},
  restDays: [],
  scheduleFetchTime: null,
}

const mapHeadToShift = (head: InputShiftHead, byId: Record<string, Shift>): Shift => {
  const shift = byId[head.id]

  // Update status if shift is same
  if (
    shift &&
    head.shiftId === shift.shiftId &&
    head.startDateTime === shift.startDateTime &&
    head.endDateTime === shift.endDateTime
  ) {
    return shift
  }

  // Otherwise schedule is changed and all bets are off
  return {
    id: head.id.toString(),
    isCommuter: head.isCommuter,
    dayType: head.dayType,
    date: head.date,
    contacts: [],
    shiftId: head.shiftId,
    created_at: unixTimestamp(),
    startDateTime: head.startDateTime,
    endDateTime: head.endDateTime,
    listStartDate: head.listStartDate,
    listEndDate: head.listEndDate,
    listId: head.listId,
    preparation: head.preparation,
    wrapUp: head.wrapUp,
    duration: head.duration,
    notifications: '',
    tasks: [],
    rests: head.rests,
    loading: false,
    error: '',
    assemblies: [],
  }
}

//TODO: handle errors
const shiftsReducer = (state: ShiftState = initialState, action: ShiftAction): ShiftState => {
  let schedule: Schedule
  let shifts: Array<Shift>

  switch (action.type) {
    case CLEAR_SHIFTS:
      return initialState

    case RELOAD_SCHEDULE:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case UPDATE_SCHEDULE:
      schedule = action.payload
      shifts = schedule.shifts.map((s) => mapHeadToShift(s, state.byId))
      // Insert shifts for shift heads
      return {
        ...state,
        loading: false,
        error: schedule.error,
        scheduleFetchTime: state.error ? state.scheduleFetchTime : new Date(),
        byId: Object.entries(replaceManyWithId(state.byId, shifts))
          .filter(([id]) => shifts.some((s) => s.id === id))
          .reduce(
            (result, [id, value]) => {
              result[id] = value
              return result
            },
            {} as Record<string, Shift>
          ),
        restDays: schedule.restDays,
      }

    default:
      return state
  }
}

export default shiftsReducer
