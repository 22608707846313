import { Actions as FarceActions } from 'farce'

export const visitURL = (url: string) => FarceActions.push(url)
export const rewriteURL = (url: string) => FarceActions.replace(url)

export const scheduleURL = (): string => '/shifts'
export const homeURL = (): string => '/'
export const shiftURL = (shiftId: string): string => `/shifts/${shiftId}`

export const shiftListFeedbackURL = (listId: string, stars: number): string =>
  `/shifts/${encodeURIComponent(listId)}/list-feedback/${stars}`
export const shiftFeedbackURL = (shiftId: string, stars: number): string =>
  `/shifts/${shiftId}/feedback/${stars}`
